import React, { ReactNode, } from "react"
import "../themes/css/bootstrap.min.css"
import "../themes/css/now-ui-kit.min.css"
import "../themes/demo/demo.css"
import "./layout.css"
import Helmet from "react-helmet" 

type SeoContent = {
  title: string,
  description: string,
  keyword: string
}

export type LayoutProps = {
  children: ReactNode
  defaultClass?: string
  lang?: string
  seo: SeoContent
  footerType?: "HOMEPAGE" | "SIMPLE"
  navStayle?: "TRANSPARANT" | "SIMPLE"
}

const Layout = (props: LayoutProps) => {
  
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: props.lang ? props.lang : '',
        }}
        bodyAttributes={{ class: 'index-page sidebar-collapse' }}
        title={props.seo.title} 
      >
        <meta name="description" content={props.seo.description} />
        <meta name="og:title" property="og:title" content={props.seo.title} />
        <meta name="og:description" property="og:description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keyword} />
        <meta name="author" content="I Putu Arka Suryawan " />
        <meta name="robots" content="index, follow"></meta>
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.1/css/all.css" />  
        <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0056/1606.js" async={true}></script>        
      </Helmet>
      <div className="wrapper">
        <div className="main" style={{ maxWidth: 1920, margin: 'auto' }}>
          {props.children}
        </div>
      </div>

    </>
  )
}


export default Layout
